
const LANG_EN = {
  COMPETITION: 'Competition',
  TEAM: 'Team',
  SEASON: 'Season',
  GROUP: 'Group',
  PHASE: 'Phase',
  VENUE: 'Venue',
  TIMEKEEPER: 'Timekeeper',
  REFEREE: 'Referee',
  ALL: 'All',
  NAME: 'Name',
  SEARCH: 'Search',
  STANDINGS: 'Standings',
  GOALIESSTATS: 'Goalie Statistics',
  PLAYERSSTATS: 'Player Statistics',
  // HOME_TEAM: 'Host team',
  // AWAY_TEAM: 'Guest team',
  EMBED_GAME_TITLE: 'Game Details',
  EMBED_GAMES_TITLE: 'Games',
  EMBED_SEASON_TERMS_TITLE: 'Propositions',
  UNKNOWN: 'Unknown',
  UNKNOWN_GROUP: 'Unknown group',
  UNKNOWN_PHASE: 'Unknown phase',
  UNKNOWN_VENUE: 'Unknown venue',
  UNKNOWN_TIME: 'Unknown time',
  MONTHS: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ],
  DAYS: [
    "Sun",
    "Mon",
    "Tue",
    "Wed",
    "Thu",
    "Fri",
    "Sat",
  ],
  DAYS_LONG: [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday"
  ],
  STANDINGS_COLUMN_SHORTS: {
    'Rank': '#',
    'Total Games': 'GP',
    'Wins': 'W',
    'Draws': 'D',
    'Losses': 'L',
    'Score': 'S',
    'Total Points': 'PT',
    'Total Penalty Minutes': 'PIM',
  },  
  STANDINGS_COLUMN_TOOLTIPS: {
    'Total Games': 'Games played',
    'Wins': 'Wins',
    'Draws': 'Draws',
    'Losses': 'Losses',
    'Score': 'Score',
    'Total Points': 'Points',
    'Total Penalty Minutes': 'Total Penalty Minutes',
  },
  PLAYERSSTATS_COLUMN_SHORTS: {
    'Level': 'L',
    'Rank': '#',
    'GP': 'GP',
    'G': 'G',
    'A': 'A',
    'P': 'P',
    'PIM': 'PIM',
    'PPG': 'PPG',
    'SHG': 'SHG',
    'Stars': 'Stars',
  }, 
  PLAYERSSTATS_COLUMN_TOOLTIPS: {
    'Level': 'Level',
    'GP': 'Games played',
    'G': 'Goals',
    'A': 'Assists',
    'P': 'Points',
    'PIM': 'Penalty Minutes',
    'PPG': 'Power Play Goals',
    'SHG': 'Shorthanded Goals',
    'Stars': 'Stars',
  },
  GOALIESSTATS_COLUMN_SHORTS: {
    'Level': 'L',
    'Rank': '#',
    'GP': 'GP',
    'Svs': 'Svs',
    'GA': 'GA',
    'GAA': 'GAA',
    'SvP': 'SvP',
    'SO': 'SO',
    'PIM': 'PIM',
    'W': 'W',
    'D': 'D',
    'L': 'L',
  },
  GOALIESSTATS_COLUMN_TOOLTIPS: {
    'Level': 'Level',
    'Svs': 'Saves',
    'GA': 'Goals allowed total',
    'GAA': 'Goals allowed average per game',
    'SvP': 'Saves percentage, saves/(saves+goals)',
    'SO': 'Shotouts - number of games without allowed goals',
    'PIM': 'Total Penalty Minutes',
    'W': 'Wins',
    'D': 'Draw/Ties',
    'L': 'Losses',
  },
  ERROR_LOADING_DATA: 'Error loading data',
  PLEASE_REFRESH_PAGE: 'Please refresh this page in a while...',

  PLEASE_SELECT: 'Please select',
  NEW_PLAYER_TITLE: 'New Player Registration',
  ALREADY_A_MEMBER_OF_A_TEAM: 'I am already a member of another team in the PHM Cup',
  ALREADY_A_MEMBER_OF: 'Already a member of',
  FIRST_NAME: 'First Name',
  LAST_NAME: 'Last Name',
  PLAYER_STATUS: 'Player Status',
  PLAYER_PROFILE_URL: 'Player Profile URL',
  JERSEY_NUMBER: 'Jersey Number',
  POSITION: 'Position',
  GENDER: 'Gender',
  BIRTHDATE: 'Birthdate',
  CITY_OF_BIRTH: 'City of Birth',
  COUNTRY_OF_BIRTH: 'Country of Birth',
  STICK: 'Stick',
  HEIGHT: 'Height',
  WEIGHT: 'Weight',
  EMAIL: 'E-mail',
  PHONE: 'Phone',
  AVATAR: 'Avatar',
  MALE: 'Male',
  FEMALE: 'Female',
  LEFT: 'Left',
  RIGHT: 'Right',
  SUBMIT: 'Submit',
  REQUIRED_FIELD: 'Required field',
  CONGRATULATIONS: 'Congratulations',
  WE_HAVE_RECEIVED_YOUR_REGISTRATION: 'We have received your registration.',
  WE_WILL_CONTACT_YOU_SOON: 'We will contact you soon',
  YOUR_PHM: 'Your PHM',
  REIGSTER_ANOTHER_PLAYER: 'Register another player',
  KONT_SHORT: 'forf.',
  NO_GAMES_TO_DISPLAY: 'There are no games to display.',
  TODAY: 'Today',
  FUTURE: 'Future',
  PAST: 'Past',
  NO_GAMES_TODAY: 'There are no games today according to the filters in the header',
}

const LANG_CS = {
  COMPETITION: 'Liga',
  TEAM: 'Tým',
  SEASON: 'Sezóna',
  GROUP: 'Divize',
  PHASE: 'Fáze',
  VENUE: 'Hala',
  TIMEKEEPER: 'Časoměřič',
  REFEREE: 'Rozhodčí',
  ALL: 'Vše',
  NAME: 'Jméno',
  SEARCH: 'Vyhledat',
  STANDINGS: 'Tabulky',
  GOALIESSTATS: 'Brankářské statistiky',
  PLAYERSSTATS: 'Hráčské statistiky',
  // HOME_TEAM: 'Domácí',
  // AWAY_TEAM: 'Hosté',
  EMBED_GAME_TITLE: 'Detail zápasu',
  EMBED_GAMES_TITLE: 'Zápasy',
  EMBED_SEASON_TERMS_TITLE: 'Propozice',
  UNKNOWN: 'Není znám',
  UNKNOWN_GROUP: 'Neznámá skupina',
  UNKNOWN_PHASE: 'Neznámá fáze',
  UNKNOWN_VENUE: 'Neznámá hala',
  UNKNOWN_TIME: 'Neznámý čas',
  MONTHS: [
    "Leden",
    "Únor",
    "Březen",
    "Duben",
    "Květen",
    "Červen",
    "Červenec",
    "Srpen",
    "Září",
    "Říjen",
    "Listopad",
    "Prosinec", 
  ],
  DAYS: [
    "Ne",
    "Po",
    "Út",
    "St",
    "Čt",
    "Pá",
    "So",
  ],
  DAYS_LONG: [
    "neděle",
    "pondělí",
    "úterý",
    "středa",
    "čtvrtek",
    "pátek",
    "sobota",
  ],
  STANDINGS_COLUMN_SHORTS: {
    'Rank': '#',
    'Total Games': 'Z',
    'Wins': 'V',
    'Draws': 'R',
    'Losses': 'P',
    'Score': 'S',
    'Total Points': 'B',
    'Total Penalty Minutes': 'TM',
  },
  STANDINGS_COLUMN_TOOLTIPS: {
    'Total Games': 'Odehrané zápasy',
    'Wins': 'Vítězství',
    'Draws': 'Remízy',
    'Losses': 'Prohry',
    'Score': 'Skóre',
    'Total Points': 'Body',
    'Total Penalty Minutes': 'Trestné minuty',
  },
  PLAYERSSTATS_COLUMN_SHORTS: {
    'Level': 'Ú',
    'Rank': '#',
    'GP': 'GP',
    'G': 'G',
    'A': 'A',
    'P': 'P',
    'PIM': 'PIM',
    'PPG': 'PPG',
    'SHG': 'SHG',
    'Stars': 'Hvězdy',
  }, 
  PLAYERSSTATS_COLUMN_TOOLTIPS: {
    'Level': 'Úroveň',
    'GP': 'Odehrané zápasy',
    'G': 'Góly',
    'A': 'Asistence',
    'P': 'Body',
    'PIM': 'Trestné minuty',
    'PPG': 'Góly v přesilovce',
    'SHG': 'Góly v oslabení',
    'Stars': 'Hvězdy',
  },
  GOALIESSTATS_COLUMN_SHORTS: {
    'Level': 'Ú',
    'Rank': '#',
    'GP': 'GP',
    'Svs': 'Svs',
    'GA': 'GA',
    'GAA': 'GAA',
    'SvP': 'SvP',
    'SO': 'SO',
    'PIM': 'PIM',
    'W': 'W',
    'D': 'D',
    'L': 'L',
  },
  GOALIESSTATS_COLUMN_TOOLTIPS: {
    'Level': 'Úroveň',
    'GP': 'Odehrané zápasy',
    'Svs': 'Počet zákroků',
    'GA': 'Obdržené góly',
    'GAA': 'Průměr gólů na zápas',
    'SvP': 'Úspěšnost',
    'SO': 'Čistá konta',
    'PIM': 'Trestné minuty',
    'W': 'Vítězství',
    'D': 'Remízy',
    'L': 'Prohry',
  },
  ERROR_LOADING_DATA: 'Chyba při načítání dat',
  PLEASE_REFRESH_PAGE: 'Prosím obnovte tuto stránku za chvíli...',

  PLEASE_SELECT: 'Prosím vyberte',
  NEW_PLAYER_TITLE: 'Registrace nového hráče',
  ALREADY_A_MEMBER_OF_A_TEAM: 'Jsem již členem jiného týmu v PHM Cupu',
  ALREADY_A_MEMBER_OF: 'Již členem týmu',
  FIRST_NAME: 'Jméno',
  LAST_NAME: 'Příjmení',
  PLAYER_STATUS: 'Status hráče',
  PLAYER_PROFILE_URL: 'URL profilu hráče',
  JERSEY_NUMBER: 'Číslo dresu',
  POSITION: 'Pozice',
  GENDER: 'Pohlaví',
  BIRTHDATE: 'Datum narození',
  CITY_OF_BIRTH: 'Místo narození',
  COUNTRY_OF_BIRTH: 'Stát narození',
  STICK: 'Hůl',
  HEIGHT: 'Výška',
  WEIGHT: 'Váha',
  EMAIL: 'E-mail',
  PHONE: 'Telefon',
  AVATAR: 'Avatar',
  MALE: 'Muž',
  FEMALE: 'Žena',
  LEFT: 'Levá',
  RIGHT: 'Pravá',
  SUBMIT: 'Odeslat',
  REQUIRED_FIELD: 'Povinné pole',
  CONGRATULATIONS: 'Gratulujeme',
  WE_HAVE_RECEIVED_YOUR_REGISTRATION: 'Přijali jsme Vaši novou registraci.',
  WE_WILL_CONTACT_YOU_SOON: 'Brzy se Vám ozveme',
  YOUR_PHM: 'Váše PHM',
  REIGSTER_ANOTHER_PLAYER: 'Registrovat dalšího hráče',
  KONT_SHORT: 'kont.',
  NO_GAMES_TO_DISPLAY: 'Nejsou žádné zápasy k zobrazení.',
  TODAY: 'Dnes',
  FUTURE: 'Budoucí',
  PAST: 'Minulé',
  NO_GAMES_TODAY: 'Dnes se nehraje žádný zápas dle výběru filtrů v hlavičce',
}

/* Embed Game */
LANG_CS['EMBED_GAME_SHOTS'] = 'Střely';
LANG_EN['EMBED_GAME_SHOTS'] = 'Shots';
LANG_CS['EMBED_GAME_FACEOFFS'] = 'Vhazování';
LANG_EN['EMBED_GAME_FACEOFFS'] = 'Face Offs';
LANG_CS['EMBED_GAME_FACEOFFS_SHORT'] = 'Vhazování';
LANG_EN['EMBED_GAME_FACEOFFS_SHORT'] = 'FO';
LANG_CS['EMBED_GAME_PENALTYMINUTES'] = 'Trestné minuty';
LANG_EN['EMBED_GAME_PENALTYMINUTES'] = 'Penalty Minutes';
LANG_CS['EMBED_GAME_PENALTYMINUTES_SHORT'] = 'TM';
LANG_EN['EMBED_GAME_PENALTYMINUTES_SHORT'] = 'PM';
LANG_CS['KONTUMACE_ADJUSTED_MESSAGE'] = 'Výsledek byl upraven z důvodu kontumace (původní výsledek {homeTeamGoals} : {awayTeamGoals})';
LANG_EN['KONTUMACE_ADJUSTED_MESSAGE'] = 'The result was adjusted due to forfeiture (original result {homeTeamGoals} : {awayTeamGoals})';
LANG_CS['EMBED_GAME_EVENTS_TITLE'] = 'Události hry';
LANG_EN['EMBED_GAME_EVENTS_TITLE'] = 'Game Events';
LANG_CS['EMBED_GAME_GOAL'] = 'Gól';
LANG_EN['EMBED_GAME_GOAL'] = 'Goal';
LANG_CS['EMBED_GAME_PENALTY'] = 'Trest';
LANG_EN['EMBED_GAME_PENALTY'] = 'Penalty';
LANG_CS['EMBED_GAME_PENALTYSHOT_SHORT'] = 'TS';
LANG_EN['EMBED_GAME_PENALTYSHOT_SHORT'] = 'PS';
LANG_CS['EMBED_GAME_ASSISTED_SHORT'] = 'as.';
LANG_EN['EMBED_GAME_ASSISTED_SHORT'] = 'as.';
LANG_CS['EMBED_GAME_ASSISTED_AND'] = 'a';
LANG_EN['EMBED_GAME_ASSISTED_AND'] = 'and';
LANG_CS['EMBED_GAME_TEAM_LINEUPS'] = 'Soupiska týmů';
LANG_EN['EMBED_GAME_TEAM_LINEUPS'] = 'Team Lineups';
LANG_CS['EMBED_GAME_DATA_NOT_AFFECTED_MESSAGE'] = 'Data nemají vliv na statistiky hráčů z důvodu kontumace.';
LANG_EN['EMBED_GAME_DATA_NOT_AFFECTED_MESSAGE'] = 'The data do not affect player statistics due to forfeiture.';
LANG_CS['EMBED_GAME_LINEUP_NAME'] = 'Jméno';
LANG_EN['EMBED_GAME_LINEUP_NAME'] = 'Name';
LANG_CS['EMBED_GAME_LINEUP_STATUS'] = 'Status';
LANG_EN['EMBED_GAME_LINEUP_STATUS'] = 'Status';
LANG_CS['EMBED_GAME_LINEUP_STATUS_SHORT'] = 'S';
LANG_EN['EMBED_GAME_LINEUP_STATUS_SHORT'] = 'S';
LANG_CS['EMBED_GAME_LINEUP_LEVEL'] = 'Úroveň';
LANG_EN['EMBED_GAME_LINEUP_LEVEL'] = 'Level';
LANG_CS['EMBED_GAME_LINEUP_LEVEL_SHORT'] = 'Ú';
LANG_EN['EMBED_GAME_LINEUP_LEVEL_SHORT'] = 'L';
LANG_CS['EMBED_GAME_LINEUP_JERSEY'] = 'Dres';
LANG_EN['EMBED_GAME_LINEUP_JERSEY'] = 'Jersey';
LANG_CS['EMBED_GAME_LINEUP_JERSEY_SHORT'] = 'D';
LANG_EN['EMBED_GAME_LINEUP_JERSEY_SHORT'] = 'J';
LANG_CS['EMBED_GAME_LINEUP_POSITION'] = 'Pozice';
LANG_EN['EMBED_GAME_LINEUP_POSITION'] = 'Position';
LANG_CS['EMBED_GAME_LINEUP_POSITION_SHORT'] = 'P';
LANG_EN['EMBED_GAME_LINEUP_POSITION_SHORT'] = 'P';
LANG_CS['EMBED_GAME_LINEUP_GOALS'] = 'Góly';
LANG_EN['EMBED_GAME_LINEUP_GOALS'] = 'Goals';
LANG_CS['EMBED_GAME_LINEUP_GOALS_SHORT'] = 'G';
LANG_EN['EMBED_GAME_LINEUP_GOALS_SHORT'] = 'G';
LANG_CS['EMBED_GAME_LINEUP_ASSISTS'] = 'Asistence';
LANG_EN['EMBED_GAME_LINEUP_ASSISTS'] = 'Assists';
LANG_CS['EMBED_GAME_LINEUP_ASSISTS_SHORT'] = 'A';
LANG_EN['EMBED_GAME_LINEUP_ASSISTS_SHORT'] = 'A';
LANG_CS['EMBED_GAME_LINEUP_POINTS'] = 'Body';
LANG_EN['EMBED_GAME_LINEUP_POINTS'] = 'Points';
LANG_CS['EMBED_GAME_LINEUP_POINTS_SHORT'] = 'B';
LANG_EN['EMBED_GAME_LINEUP_POINTS_SHORT'] = 'P';
LANG_CS['EMBED_GAME_LINEUP_PENALTY_MINUTES'] = 'Trestné minuty';
LANG_EN['EMBED_GAME_LINEUP_PENALTY_MINUTES'] = 'Penalty Minutes';
LANG_CS['EMBED_GAME_LINEUP_PENALTY_MINUTES_SHORT'] = 'TM';
LANG_EN['EMBED_GAME_LINEUP_PENALTY_MINUTES_SHORT'] = 'PM';

const LANGUAGES = {
  cs: LANG_CS,
  en: LANG_EN,
}

const useLang = (lang) => {
  const LANG = LANGUAGES[lang] ?? LANGUAGES['en'];
  return LANG;
}

export default useLang;
